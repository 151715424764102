import * as skus from "./product-skus";
import { productCategories } from "./product-categories";
import { squareImages } from "./product-images";

export const HANBLADE = {
  id: skus.HANBLADE_SKU,
  name: "Extra Handle + 1 Blade",
  priceInPence: 1200,
  description: `Our chrome-plated razor handle, ergonomically designed to rest perfectly in your hand. With a knurled section towards the top, it's easy to maintain great control over your shave, even when wet.`,
  slug: "mens-razor-blade-and-handle",
  optionsDefaults: { engraved_initials: "" },
  image: squareImages[skus.HANBLADE_SKU],
  category: productCategories.shaving.id,
  topup: {
    image:
      "https://images.ctfassets.net/itvcls9imtx8/1kc95ojNhWmhn6agDPLDXy/b0da8b70b758d5a67579a03883de91d8/razor-single.jpg",
    optionsDefaults: { engraved_initials: "   " },
    description: "Each extra handle includes 1 blade attached.",
  },
  ukOnly: false,
  outOfStock: false,
};

export const GOLD_HANDLE = {
  id: skus.GOLD_HANDLE_SKU,
  name: "Golden Razor Handle",
  priceInPence: 5000,
  description: `Our chrome-plated razor handle, ergonomically designed to rest perfectly in your hand. With a knurled section towards the top, it's easy to maintain great control over your shave, even when wet. Immersed in a luxurious gold finish. Includes 1 razor blade and blade protector.`,
  optionsDefaults: { engraved_initials: "" },
  image: squareImages[skus.GOLD_HANDLE_SKU],
  category: productCategories.gifts.id,
  topup: {
    optionsDefaults: { engraved_initials: "   " },
    description: "Each extra handle includes 1 blade attached.",
  },
  ukOnly: false,
  outOfStock: false,
};

export const BALM = {
  id: skus.BALM_SKU,
  priceInPence: 700,
  name: "Post-Shave Balm",
  image: squareImages[skus.BALM_SKU],
  description: `Refresh and moisturise your skin with our cooling Post-Shave Balm. Use after shaving to help restore your skin’s natural moisture balance and reduce irritation. Blended with mint and honey extracts to soothe and condition. 75ml.`,
  slug: "after-shave-balm",
  category: productCategories.shaving.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const BLADE_PROTECTOR = {
  id: skus.BLADE_PROTECTOR_SKU,
  priceInPence: 500,
  name: "Red Blade Cover",
  image: squareImages[skus.BLADE_PROTECTOR_SKU],
  description: `Custom made to fit perfectly on your Cornerstone razor. Protect your blades when travelling`,
  category: productCategories.travel.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const BLADE = {
  id: skus.BLADE_SKU,
  name: "Razor Blade",
  ukOnly: false,
  outOfStock: false,
};

export const BLADECART_5 = {
  id: skus.BLADECART_5_SKU,
  name: "6 Razor Blades",
  ukOnly: false,
  outOfStock: false,
};

export const BLADECART_6 = {
  id: skus.BLADECART_6_SKU,
  name: "6 Razor Blades",
  ukOnly: false,
  outOfStock: false,
};

export const BLADES_5 = {
  id: skus.BLADES_5_SKU,
  name: "6 Razor Blades",
  ukOnly: false,
  outOfStock: false,
};

export const BLADES_6 = {
  id: skus.BLADES_6_SKU,
  name: "6 Razor Blades",
  slug: "mens-razor-blades",
  ukOnly: false,
  outOfStock: false,
};

export const BODYWASH = {
  id: skus.BODYWASH_SKU,
  name: "Body Wash",
  price: 5,
  priceInPence: 500,
  image: squareImages[skus.BODYWASH_SKU],
  description: `Blended with lemon, ginger and ginseng extract to give an invigorating wash. Guaranteed to get you up and going in the morning. 300ml.`,
  category: productCategories.body.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const CREAM = {
  id: skus.CREAM_SKU,
  priceInPence: 850,
  name: "Shave Cream",
  image: squareImages[skus.CREAM_SKU],
  description: `Protect and condition your skin whilst you shave, with a rich foaming lather blended with willow bark and bamboo. 150ml.`,
  slug: "shaving-cream",
  category: productCategories.shaving.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const DEODORANT = {
  id: skus.DEODORANT_SKU,
  name: "Antiperspirant Deodorant",
  priceInPence: 400,
  image: squareImages[skus.DEODORANT_SKU],
  description: `Our alcohol-free, paraben-free antiperspirant deodorant offers 48 hours of fresh, reliable protection. To save space and reduce waste, we’ve compressed our deodorant - it still lasts just as long as a normal sized can. 100ml.`,
  slug: "deodorant",
  category: productCategories.body.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const FACE_WASH = {
  id: skus.FACE_WASH_SKU,
  name: "Daily Face Wash",
  priceInPence: 700,
  image: squareImages[skus.FACE_WASH_SKU],
  description: `An oil-free, sensitive daily face wash - blended with mint and cucumber extracts to deliver a gentle, invigorating clean. It removes excess dirt and impurities from your skin, making your skin feel energised and refreshed every morning. 150ml.`,
  slug: "mens-face-wash",
  category: productCategories.body.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const FLOSS = {
  id: skus.FLOSS_SKU,
  name: "Gentle Daily Floss Tape",
  priceInPence: 300,
  image: squareImages[skus.FLOSS_SKU],
  description: `Coated with PTFE to glide comfortably between your teeth. Helps keep gums clear of plaque and food debris. 25m.`,
  slug: "dental-floss",
  category: productCategories.mouth.id,
  topup: {
    optionsDefaults: {},
    name: "Floss Tape",
  },
  ukOnly: false,
  outOfStock: false,
};

export const FOUR_BRUSH_CART = {
  id: skus.FOUR_BRUSH_CART_SKU,
  name: "4 Deep-Clean Toothbrush Heads",
  priceInPence: 1200,
  image: squareImages[skus.FOUR_BRUSH_CART_SKU],
  description: `With soft, round-ended bristles, our toothbrush heads help protect your enamel and gums from damage during brushing, to leave your mouth feeling healthy and clean each morning.`,
  slug: "toothbrush-heads",
  category: productCategories.mouth.id,
  topup: {
    name: "4 Toothbrush Heads",
    description:
      "Compatible with most Oral-B toothbrush handles. Set of 4.<br><br><small>* Compatible with: ProfessionalCare SmartSeries, Vitality**, Oral-B Triumph, ProfessionalCare, Pro-Health, Trizone, Healthy Clean, AdvancePower and Pro Family.</small><br><br><small>** Does not fit Vitality Sonic, CrossAction Power, Oral-B Sonic Complete, Oral-B Complete, 3D White, and Pulsonic.</small>",
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const ONE_BRUSH_CART = {
  id: skus.ONE_BRUSH_CART_SKU,
  name: "Deep-Clean Toothbrush Head",
  priceInPence: 300,
  image: "",
  description: `With soft, round-ended bristles, our toothbrush heads help protect your enamel and gums from damage during brushing, to leave your mouth feeling healthy and clean each morning.`,
  slug: "toothbrush-head",
  category: productCategories.mouth.id,
  topup: { optionsDefaults: {} },
  ukOnly: false,
  outOfStock: false,
};

export const GEL = {
  id: skus.GEL_SKU,
  priceInPence: 850,
  name: "Shave Gel",
  image: squareImages[skus.GEL_SKU],
  description: `Protect your skin from razor burn with our gel containing eucalyptus oil and ginger extracts. Formulated to give a comfortable, irritation-free shave. 150ml.`,
  slug: "shaving-gel",
  category: productCategories.shaving.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const GIFTSET = {
  id: skus.GIFTSET_SKU,
  name: "Luxury Shaving Gift Set",
  price: 45,
  priceInPence: 4500,
  image: squareImages[skus.GIFTSET_SKU],
  description: `Everything a man needs for a smooth, comfortable shave in a single box. Contains a personalised chrome-plated razor handle, 6 razor blades, shave gel, pre-shave scrub and post-shave balm. We also include a £10 voucher so the recipient can sign up for one of our shave plans when they've finished the box.`,
  slug: "full-shaving-set",
  optionsDefaults: {
    engraved_initials: "",
  },
  category: productCategories.gifts.id,
  topup: {
    optionsDefaults: {
      engraved_initials: "",
    },
  },
  ukOnly: false,
  outOfStock: false,
};

export const LUXGIFTSETGOLD = {
  id: skus.LUXGIFTSETGOLD_SKU,
  name: "Golden Luxury Shaving Gift Set",
  price: 60,
  priceInPence: 6000,
  image: squareImages[skus.LUXGIFTSETGOLD_SKU],
  description: `Everything a man needs for a smooth, comfortable shave in a single box. Contains a limited edition personalised golden razor handle, 6 razor blades, shave gel, pre-shave scrub and post-shave balm. We also include a £10 voucher so the recipient can sign up for one of our shave plans when they've finished the box.`,
  slug: "full-shaving-set",
  optionsDefaults: {
    engraved_initials: "",
  },
  category: productCategories.gifts.id,
  topup: {
    optionsDefaults: {
      engraved_initials: "",
    },
  },
  ukOnly: false,
  outOfStock: false,
};

export const LUXURY_WASHSET = {
  id: skus.LUXURY_WASHSET_SKU,
  name: "Luxury Wash Set",
  price: 20,
  priceInPence: 2000,
  image: squareImages[skus.LUXURY_WASHSET_SKU],
  description:
    "Our complete wash set, perfect for your gym or travel bag. Contains our 48 hour antiperspirant deodorant, energising body wash, oil-free face wash and hydrating daily moisturiser.",
  slug: "lux-wash-set",
  category: productCategories.gifts.id,
  topup: { optionsDefaults: {} },
  ukOnly: true,
  outOfStock: false,
};

export const MINI_GIFTSET = {
  id: skus.MINI_GIFTSET_SKU,
  name: "Mini Shaving Gift Set",
  price: 25,
  priceInPence: 2500,
  image: squareImages[skus.MINI_GIFTSET_SKU],
  description: `Everything a man needs for a smooth, comfortable shave, just in a mini gift box! Contains a personalised chrome razor handle, 1 German-engineered razor blade, a Shave Cream (150ml), and a red blade protector. We also include a £10 voucher so the recipient can sign up for one of our shave plans when they've finished the box.`,
  slug: "mini-shaving-set",
  optionsDefaults: {
    engraved_initials: "",
  },
  category: productCategories.gifts.id,
  topup: {
    optionsDefaults: {
      engraved_initials: "",
    },
  },
  ukOnly: false,
  outOfStock: false,
};

export const HANBLADEV1 = {
  id: skus.HANBLADEV1_SKU,
  name: "Original Handle",
  ukOnly: false,
  outOfStock: false,
};

export const HANDLE_V1 = {
  id: skus.HANDLE_V1_SKU,
  name: "Original Handle",
  ukOnly: false,
  outOfStock: false,
};

export const HANDLE_V2_1 = {
  ...HANBLADE,
  id: skus.HANDLE_V2_1_SKU,
  ukOnly: false,
  outOfStock: false,
};

export const HANDLE = {
  ...HANBLADE,
  id: skus.HANDLE_SKU,
  ukOnly: false,
  outOfStock: false,
};

export const HANBLADEV2 = {
  ...HANBLADE,
  id: skus.HANBLADEV2_SKU,
  ukOnly: false,
  outOfStock: false,
};

export const MOISTURISER = {
  id: skus.MOISTURISER_SKU,
  priceInPence: 700,
  name: "Daily Moisturiser",
  image: squareImages[skus.MOISTURISER_SKU],
  description: `Blended with mint and aloe vera to refresh and hydrate. Our lightweight and non-greasy daily moisturiser is perfect for daily use or after shaving. 100ml.`,
  slug: "mens-oil-free-moisturiser",
  category: productCategories.body.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const MOUTH_WASH = {
  id: skus.MOUTH_WASH_SKU,
  name: "Daily Fresh Mint Mouthwash",
  priceInPence: 400,
  image: squareImages[skus.MOUTH_WASH_SKU],
  description: `Blended with icy peppermint for fresh breath and rich in fluoride to help strengthen and remineralise enamel. 500ml.`,
  slug: "mouthwash",
  category: productCategories.mouth.id,
  topup: {
    optionsDefaults: {},
    name: "Mint Mouthwash",
  },
  ukOnly: false,
  outOfStock: false,
};

export const RAZOR = {
  id: skus.RAZOR_SKU,
  priceInPence: 1700,
  name: "6 Razor Blades",
  image: squareImages[skus.RAZOR_SKU],
  description: `Made from Japanese chromium steel, our blades stay sharper for longer. We coat our blades in a layer of titanium and diamond grade carbon to guarantee the perfect shave.`,
  category: productCategories.shaving.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const SCRUB = {
  id: skus.SCRUB_SKU,
  price: 8,
  priceInPence: 800,
  name: "Pre-Shave Face Scrub",
  image: squareImages[skus.SCRUB_SKU],
  description: `Exfoliate your skin with our face scrub blended with volcanic sand and cedarwood. Use before shaving to release ingrown hairs, reduce shaving spots, and unblock pores for smoother and clearer skin. 100ml.`,
  slug: "mens-face-scrub",
  category: productCategories.shaving.id,
  topup: {
    name: "Face Scrub",
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const SHAMPOO = {
  id: skus.SHAMPOO_SKU,
  name: "2-In-1 Shampoo & Conditioner",
  price: 7,
  priceInPence: 700,
  image: squareImages[skus.SHAMPOO_SKU],
  description: `Our anti-dandruff & hair strengthening 2-in-1 shampoo & conditioner is a protein-based formula blended specifically to help strengthen each hair strand. Enriched with Juniper to help maintain a healthy and dandruff-free scalp, it is also formulated with the optimal amount of conditioner to help nourish hair without leaving it limp or greasy. 400ml.`,
  slug: "anti-dandruff-shampoo",
  category: productCategories.hair.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: true,
};

export const TOOTH_PASTE = {
  id: skus.TOOTH_PASTE_SKU,
  name: "Gentle Whitening Toothpaste",
  priceInPence: 500,
  image: squareImages[skus.TOOTH_PASTE_SKU],
  description: `Gently removes stains to restore your teeth’s natural whiteness. Rich in fluoride to help strengthen and remineralise enamel. Peroxide-free to minimize risk of sensitivity. 100ml.`,
  slug: "whitening-toothpaste",
  category: productCategories.mouth.id,
  topup: {
    optionsDefaults: {},
    name: "Whitening Toothpaste",
  },
  ukOnly: false,
  outOfStock: false,
};

export const TRAVEL_CREAM = {
  id: skus.TRAVEL_CREAM_SKU,
  priceInPence: 500,
  name: "Travel Shave Cream",
  image: squareImages[skus.TRAVEL_CREAM_SKU],
  description: `Protect and condition your skin whilst you shave, with a rich foaming lather blended with willow bark and bamboo. 75ml.`,
  category: productCategories.travel.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const TRAVEL_GEL = {
  id: skus.TRAVEL_GEL_SKU,
  priceInPence: 500,
  name: "Travel Shave Gel",
  image: squareImages[skus.TRAVEL_GEL_SKU],
  description: `Our shave gel in 75ml size.`,
  category: productCategories.travel.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const TRIALBOX = {
  id: skus.TRIALBOX_SKU,
  name: "Trial Box",
  optionsDefaults: {
    engraved_initials: "",
  },
  ukOnly: false,
  outOfStock: false,
};

export const WASHBAG = {
  id: skus.WASHBAG_SKU,
  name: "Leather Wash Bag",
  price: 60,
  priceInPence: 6000,
  image: squareImages[skus.WASHBAG_SKU],
  optionsDefaults: {},
  description: `Store your shaving essentials in style. The crushed buffalo leather and waterproof lining combine both style and practicality and make this wash bag ideal for travelling or using as storage at home. Skincare products not included`,
  slug: "leather-washbag",
  category: productCategories.gifts.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const VITAMINS = {
  id: skus.VITAMINS_SKU,
  name: "Multivitamins",
  price: 6,
  priceInPence: 600,
  image: squareImages[skus.VITAMINS_SKU],
  description: `Our Energy Boosting Multivitamins blend a combination of micronutrients; including Vitamin D, C, A and Copper. Together they help to support the immune system, tissue health, reduce tiredness and maintain normal vision. Pack of 30 tablets.`,
  slug: "mens-multivitamins",
  category: productCategories.body.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const SILD8X100 = {
  id: skus.SILD8X100_SKU,
  priceInPence: 3000,
  name: "8 Sildenafil, 100mg",
  image: squareImages[skus.SILD8X100_SKU],
  description: `As the active ingredient in Viagra, Sildenafil helps to relax the blood vessels in the penis, allowing increased blood flow when sexually excited. Pack of 8.`,
  category: productCategories.sex.id,
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const SILD8X50 = {
  id: skus.SILD8X50_SKU,
  priceInPence: 2500,
  name: "8 Sildenafil, 50mg",
  image: squareImages[skus.SILD8X50_SKU],
  description: `As the active ingredient in Viagra, Sildenafil helps to relax the blood vessels in the penis, allowing increased blood flow when sexually excited. Pack of 8.`,
  category: productCategories.sex.id,
  slug: "erectile-dysfunction-treatment",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const CONDOM10 = {
  id: skus.CONDOM10_SKU,
  priceInPence: 1200,
  name: "10 Ultra Thin Condoms",
  image: squareImages[skus.CONDOM10_SKU],
  description: `Made from synthetic latex, our ultra thin, SKYN® made condoms offer a natural and comfortable feel whilst giving you the same protection as regular natural, rubber latex condoms.`,
  category: productCategories.sex.id,
  slug: "condoms",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: true,
};

export const FIN30X1 = {
  id: skus.FIN30X1_SKU,
  priceInPence: 2000,
  name: "30 Finasteride, 1mg",
  image: squareImages[skus.FIN30X1_SKU],
  description: `A daily oral tablet known as a DHT blocker. It addresses hair loss by reducing the conversion of the male hormone testosterone into DHT.  Helps to encourage hair regrowth by reducing the shrinkage of hair follicles. Pack of 30.`,
  category: productCategories.hair.id,
  slug: "hair-loss-tablet-treatment",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const MIN65 = {
  id: skus.MIN65_SKU,
  priceInPence: 2500,
  name: "Minoxidil, 60ml",
  image: squareImages[skus.MIN65_SKU],
  description: `A daily topical solution which promotes hair growth whilst preventing any further hair loss. It works by dilating blood vessels which in turn allows more blood supply and nutrients to the hair follicles, leading to the production of thicker hairs.`,
  category: productCategories.hair.id,
  slug: "hair-loss-spray-treatment",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const ORLI84X120 = {
  id: skus.ORLI84X120_SKU,
  priceInPence: 4500,
  name: "84 Orlistat, 120mg",
  image: squareImages[skus.ORLI84X120_SKU],
  description:
    "Our treatment is clinically-proven to aid weight loss. By binding to dietary fat Orlistat stops up to one-third of it from being absorbed. When combined with a low-calorie diet and exercise, it can help you lose 50% more weight than you would lose by diet and exercise alone.",
  category: productCategories.body.id,
  slug: "weight-loss-treatment",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: true,
  outOfStock: false,
};

export const BBCOOLSOX3 = {
  id: skus.BBCOOLSOX3_SKU,
  priceInPence: 1500,
  name: "Bamboo Cool Socks (3 Pairs)",
  image: squareImages[skus.BBCOOLSOX3_SKU],
  description:
    "Bamboo is not only super soft, but it absorbs moisture 4x faster than cotton and is naturally anti-microbial - keeping your feet cool and fresh all day long! One size - fits 7-13.",
  category: productCategories.body.id,
  slug: "bamboo-cool-socks-3-pairs",
  topup: {
    optionsDefaults: {},
  },
  ukOnly: false,
  outOfStock: false,
};

export const productsBySku = {
  [skus.BALM_SKU]: BALM,
  [skus.BLADE_PROTECTOR_SKU]: BLADE_PROTECTOR,
  [skus.BLADE_SKU]: BLADE,
  [skus.BLADECART_5_SKU]: BLADECART_5,
  [skus.BLADECART_6_SKU]: BLADECART_6,
  [skus.BLADES_5_SKU]: BLADES_5,
  [skus.BLADES_6_SKU]: BLADES_6,
  [skus.BODYWASH_SKU]: BODYWASH,
  [skus.CREAM_SKU]: CREAM,
  [skus.DEODORANT_SKU]: DEODORANT,
  [skus.FACE_WASH_SKU]: FACE_WASH,
  [skus.FLOSS_SKU]: FLOSS,
  [skus.FOUR_BRUSH_CART_SKU]: FOUR_BRUSH_CART,
  [skus.ONE_BRUSH_CART_SKU]: ONE_BRUSH_CART,
  [skus.GEL_SKU]: GEL,
  [skus.GIFTSET_SKU]: GIFTSET,
  [skus.LUXGIFTSETGOLD_SKU]: LUXGIFTSETGOLD,
  [skus.MINI_GIFTSET_SKU]: MINI_GIFTSET,
  [skus.GOLD_HANDLE_SKU]: GOLD_HANDLE,
  [skus.HANDLE_V2_GOLD_SKU]: GOLD_HANDLE,
  [skus.HANBLADEV1_SKU]: HANBLADEV1,
  [skus.HANDLE_V1_SKU]: HANDLE_V1,
  [skus.HANBLADE_SKU]: HANBLADE,
  [skus.HANDLE_V2_1_SKU]: HANDLE_V2_1,
  [skus.HANDLE_SKU]: HANDLE,
  [skus.HANBLADEV2_SKU]: HANBLADEV2,
  [skus.LUXURY_WASHSET_SKU]: LUXURY_WASHSET,
  [skus.MOISTURISER_SKU]: MOISTURISER,
  [skus.MOUTH_WASH_SKU]: MOUTH_WASH,
  [skus.RAZOR_SKU]: RAZOR,
  [skus.SCRUB_SKU]: SCRUB,
  [skus.SHAMPOO_SKU]: SHAMPOO,
  [skus.TOOTH_PASTE_SKU]: TOOTH_PASTE,
  [skus.TRAVEL_CREAM_SKU]: TRAVEL_CREAM,
  [skus.TRAVEL_GEL_SKU]: TRAVEL_GEL,
  [skus.TRIALBOX_SKU]: TRIALBOX,
  [skus.WASHBAG_SKU]: WASHBAG,
  [skus.VITAMINS_SKU]: VITAMINS,
  [skus.SILD8X100_SKU]: SILD8X100,
  [skus.SILD8X50_SKU]: SILD8X50,
  [skus.FIN30X1_SKU]: FIN30X1,
  [skus.MIN65_SKU]: MIN65,
  [skus.CONDOM10_SKU]: CONDOM10,
  [skus.ORLI84X120_SKU]: ORLI84X120,
  [skus.BBCOOLSOX3_SKU]: BBCOOLSOX3,
};
